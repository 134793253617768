@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap");

body {
  background-color: #f8f8f8 !important;
}

:root {
  /* BLUE  SCHEME  */
  --color-127CF9: #127cf9;
  --color-59A3FB: #59a3fb;
  --color-88BEFC: #88befc;
  --color-B8D8FD: #b8d8fd;
  --color-E7F2FE: #e7f2fe;
  --color-FA1111: #fa1111;
  --color-FFF6E7: #fff6e7;
  --color-FDD288: #fdd288;
  --color-FAA611: #faa611;

  /* KESHAV */

  /* BLACK SCHEME */
  --color-17191C: #17191c;
  --color-5D5E60: #5d5e60;
  --color-8B8C8D: #8b8c8d;
  --color-B9BABB: #b9babb;
  --color-E8E8E8: #e8e8e8;
  --color-000000: #000000;
  --color-F8F8F8: #f8f8f8;
  --color-white: white;

  /* FONT SIZES  */
  --font-xxxl: 62px;
  --font-xxl: 49px;
  --font-xl: 39px;
  --font-lg: 31px;
  --font-md: 25px;
  --font-sm: 20px;
  --font-smll: 16px;
  --font-xsm: 10px;
  --font-xs: 20px;
  --font-xxs: 13px;
}

@media (max-width: 768px) {
  :root {
    --font-xxxl: 40px;
    --font-xxl: 30px;
    --font-xl: 34px;
    --font-lg: 27px;
    --font-md: 21px;
    --font-sm: 17px;
    --font-smll: 14px;
    --font-xs: 18px;
  }
}

@media (max-width: 567px) {
  :root {
    --font-xxxl: 38px;
    --font-xxl: 27px;
    --font-xl: 31px;
    --font-lg: 25px;
    --font-md: 19px;
    --font-sm: 11px;
    --font-smll: 11px;
    --font-xs: 10px;
  }
}

.Montserrat-font-family {
  font-family: "Montserrat", sans-serif;
}

.common-text {
  font-size: var(--font-smll);
  font-family: "Montserrat", sans-serif;
  color: var(--color-5D5E60);
  font-weight: 500;
}

.common-heading-Medium {
  font-family: "Montserrat", sans-serif;
  color: var(--color-17191C);
  font-weight: 600;
  font-size: var(--font-sm);
}

.common-heading {
  font-weight: 500;
  font-size: var(--font-xl);
  font-family: "Montserrat", sans-serif;
  color: var(--color-17191C);
}

/* FONT WEIGHT */
.f-w-600 {
  font-weight: 600 !important;
}

.f-w-500 {
  font-weight: 500 !important;
}

.btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.bg-e8e8e8 {
  background-color: var(--color-E8E8E8);
}

.bg-5D5E60 {
  background-color: var(--color-5D5E60);
}

/* FONT SIZE */
.xxs-font {
  font-size: var(--font-xxs) !important;
}

.lg-font {
  font-size: var(--font-lg) !important;
}

.xxs-font {
  font-size: var(--font-xxs) !important;
}

.smll-font {
  font-size: var(--font-smll) !important;
}

.xs-font {
  font-size: var(--font-xs) !important;
}

.sm-font {
  font-size: var(--font-sm) !important;
}

.md-font {
  font-size: var(--font-md) !important;
}

/* COLOR */
.text-white {
  color: var(--color-white) !important;
}

.text-black {
  color: var(--color-17191C);
}

.text-blue {
  color: var(--color-127CF9) !important;
}

.text-red {
  color: var(--color-FA1111);
}

.text-8B8C8D {
  color: var(--color-8B8C8D) !important;
}

.text-5D5E60 {
  color: var(--color-5D5E60) !important;
}

.text-dark-black {
  color: var(--color-000000) !important;
}

.h-75vh {
  height: 75vh;
}

.h-60vh {
  height: 60vh;
}

/* ADMINDASHBOARDMYJOB */

.my-job-head {
  font-size: var(--font-xxs);
  color: var(--color-5D5E60);
  font-weight: 500;
}

.my-job-view {
  font-size: var(--font-xxs);
  color: var(--color-5D5E60);
  font-weight: 500;
}

.inside-box-head {
  font-weight: 600;
  font-size: var(--font-smll);
  color: var(--color-17191C);
}

.inside-box-content {
  font-weight: 500;
  font-size: var(--font-xxs);
  color: var(--color-5D5E60);
}

.dollars {
  font-weight: 600;
  font-size: var(--font-smll);
  color: var(--color-127CF9);
}

.my-job-box {
  background: #ffffff;
  box-shadow: 0px 3px 15px #eff3f7;
  border-radius: 10px;
}

.inside-box-myjob {
  background: #ffffff;
  /* Black-100 */

  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 10px;
}

.inside-box-myjob:hover {
  background: #ffffff;
  box-shadow: 0px 10px 15px #eff3f7;
  border-radius: 10px;
  border: 1px solid #ffffff;
}

.my-job-messages {
  background: #ffffff;
  box-shadow: 0px 3px 15px #eff3f7;
  border-radius: 10px;
}

.inside-box-messeges {
  background: #ffffff;

  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 10px;
}

.inside-box-messeges:hover {
  background: #ffffff;
  box-shadow: 0px 10px 15px #eff3f7;
  border-radius: 10px;
  border: 1px solid #ffffff;
}

.inside-box-messeges:hover .bg-number-dipsute.no-list-dipsute {
  background: var(--color-127CF9);
  color: #ffffff;
  border: 1px solid #127cf9;
}

.inside-dispute-message-box {
  background: #ffffff;
  color: var(--color-FAA611);
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 10px;
}

.inside-dispute-message-box:hover {
  background: #ffffff;
  box-shadow: 0px 10px 15px #eff3f7;
  border-radius: 10px;
  border: 1px solid #ffffff;
}

.inside-dispute-message-box:hover .bg-number-dipsute.no-list-dipsute-messege {
  background: var(--color-FAA611);
  color: #ffffff;
  border: 1px solid #faa611;
}

.myjob-messege-head {
  color: var(--color-5D5E60);
  font-weight: 500;
  font-size: var(--font-smll);
}

.messenger-name {
  font-weight: 500;
  font-size: var(--font-smll);
  color: var(--color-17191C);
}

.my-job-scroll-bar {
  overflow: auto;
  max-height: 392px;
  /* max-height: 140px; */
}

.my-job-scroll-bar::-webkit-scrollbar {
  width: 6px;
}

.my-job-scroll-bar::-webkit-scrollbar-thumb {
  background-color: var(--color-127CF9);
  border-radius: 5px;
}

.message-scroll-bar {
  overflow: auto;
  max-height: 150px;
}

.message-scroll-bar::-webkit-scrollbar {
  width: 6px;
}

.message-scroll-bar::-webkit-scrollbar-thumb {
  background-color: var(--color-127CF9);
  border-radius: 5px;
}

.dispute-scroll-bar {
  overflow: auto;
  max-height: 130px;
}

.margin-5 {
  margin-top: -5px;
}
.dispute-scroll-bar::-webkit-scrollbar {
  width: 6px;
}

.dispute-scroll-bar::-webkit-scrollbar-thumb {
  background-color: var(--color-127CF9);
  border-radius: 5px;
}

/* form field */
.atech-input {
  background-color: var(--color-white);
  border: 1px solid var(--color-B9BABB) !important;
  border-radius: 3px;
}

.atech-input:focus {
  outline: 0;
  box-shadow: none !important;
  border: 1px solid var(--color-B9BABB) !important;
  border-radius: 3px;
}

.atech-input::placeholder {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: var(--color-8B8C8D);
  font-size: var(--font-smll);
}

textarea::placeholder {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: var(--color-8B8C8D) !important;
  font-size: var(--font-smll);
}

.atech-input[type="email"] {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: var(--color-8B8C8D);
  font-size: var(--font-smll);
}

.atech-input[type="password"] {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: var(--color-8B8C8D);
  font-size: var(--font-smll);
}

.atech-input[type="text"] {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: var(--color-8B8C8D);
  font-size: var(--font-smll);
}

.timing-popup {
  transition: 0.3s ease;
}

/* button */
.atech-primary-button {
  background-color: var(--color-127CF9);
  border-radius: 3px;
  border: 1px solid var(--color-127CF9) !important;
  transition: 0.2s;
  font-weight: 600;
  font-size: var(--font-smll);
  color: var(--color-white);
}

.atech-primary-button:hover {
  color: var(--color-127CF9) !important;
  background-color: var(--color-white) !important;
}

.atech-popup-button {
  background-color: var(--color-F8F8F8);
  border-radius: 56px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  font-size: var(--font-sm);
  color: var(--color-5D5E60);
  border: 1px solid #e8e8e8;
  transition: 0.2s;
}

.atech-popup-button:hover .atech-popup-button {
  background-color: var(--color-F8F8F8);
  border-radius: 56px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  font-size: var(--font-sm);
  color: var(--color-5D5E60);
  border: 1px solid #e8e8e8;
  transition: 0.2s;
}

.atech-popup-button-active {
  background-color: var(--color-5D5E60);
  color: var(--color-white);
  border-radius: 56px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  font-size: var(--font-sm);
  border: 1px solid #e8e8e8;
  transition: 0.2s;
}

.atech-popup-button:hover {
  background-color: var(--color-5D5E60);
  color: var(--color-white);
}

.atech-fade-button {
  border-radius: 3px;
  font-weight: 600;
  font-size: var(--font-smll);
  background-color: var(--color-F8F8F8);
  border: 1px solid var(--color-E8E8E8);
  color: var(--color-5D5E60);
  transition: 0.2s;
}

.atech-fade-button:hover {
  border-radius: 3px;
  background-color: white;
  color: var(--color-5D5E60);
}

.three-dots .dropdown-item:hover .dropdown-icon svg path {
  fill: black;
}

.three-dots .dropdown-item:hover span {
  color: black;
}

/* DATE PICKER */
.datepicker-input
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  width: 100%;
  height: 100%;
  padding: 2px 20px;
  border: 1px solid #b9babb;
}

.datepicker-input
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input:focus {
  border: 1px solid #b9babb !important;
}

.datepicker-input .react-datepicker-wrapper .react-datepicker__input-container {
  height: 100%;
}

.datepicker-input .react-datepicker-wrapper {
  height: 100%;
}

.calender-img {
  top: 20px;
  right: 29px;
}

.atech-primary-button:hover .plus-icon svg path {
  fill: var(--color-127CF9) !important;
}

/* box shadow */
.box-shadow {
  box-shadow: 0px 3px 15px #eff3f7;
}

.bg-F8F8F8 {
  background: #f8f8f8;
}

/* ADMIN DISPUTE CHAT  */

.dispute-chat-list {
  background: #ffffff;
  border: 1px solid var(--color-E8E8E8);
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s ease;
}

.dispute-chat-list:hover {
  background: var(--color-FFF6E7);
}

.dispute-active-chat {
  background: var(--color-FFF6E7);
}

.dispute-msg-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-FFF6E7);
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

/* Login form */
.eye-icon-img {
  position: absolute;
  top: 18px;
  right: 20px;
}

.A-Tech-text {
  font-size: var(--font-lg);
  font-weight: 700;
}

.loading_form {
  font-weight: 500 !important;
  font-size: var(--font-smll) !important;
}

.atech-primary-button:hover svg path {
  fill: var(--color-127CF9) !important;
}

.atech-border {
  border: 1px solid #b9babb !important;
}

.textarea-focus .form-control:focus {
  box-shadow: 0 0 0 0.25rem transparent !important;
  border: 1px solid #ced4da !important;
  border-color: #ced4da !important;
}

.textarea-focus .form-control {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: var(--color-8B8C8D) !important;
  font-size: var(--font-smll);
}

.atech-input {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: var(--color-8B8C8D) !important;
  font-size: var(--font-smll);
}

.user-img-border {
  border-radius: 50%;
  border: 2px solid #e8e8e8;
  height: 100px;
  width: 100px;
}

.user-img {
  width: 96px;
  height: 96px;
  border-radius: 50%;
}

/* SELECT FIELD */
.select-field .form-select {
  border: 1px solid var(--color-B9BABB);
}

.select-field .form-select:focus {
  border-color: var(--color-B9BABB) !important;
  outline: none !important;
  box-shadow: 0 0 0 0.25rem transparent !important;
}

.select-field .form-select {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: var(--color-8B8C8D);
  font-size: var(--font-smll);
}

.select-field .form-select {
  background-image: url(../img/svg/arrow.svg);
}

.react-select .css-yk16xz-control {
  padding: 13px;
  cursor: pointer;
}

.react-select .css-1pahdxg-control {
  padding: 13px;
  cursor: pointer;
}

.px-12px {
  padding-left: 12px;
  padding-right: 12px;
}

.react-select .css-1pahdxg-control:hover {
  border: 1px solid var(--color-B9BABB);
  box-shadow: 0 0 1px #e8e8e8;
}

.react-select .css-1uccc91-singleValue {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: var(--color-8B8C8D);
  font-size: var(--font-smll);
}

#video-modal .modal-content {
  padding: 10px;
}

.react-select .css-1wa3eu0-placeholder {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: var(--color-8B8C8D);
  font-size: var(--font-smll);
}
.h-0 {
  height: 0%;
}

/* CREATER SIDEBAR */
.dashboard-btn {
  background-color: var(--color-127CF9) !important;
  box-shadow: 0px 11px 25px -9px #96bfef;
  padding: 13px 0px !important;
  border-radius: 5px;
  cursor: pointer;
  margin: 0px 30px;
}

.sidebar2-text {
  font-weight: 600;
  font-size: var(--font-sm);
  color: var(--color-white);
}

.creater-sidebar {
  background-color: white;
  width: 300px;
  box-shadow: 4px 0px 15px #f1f5f8;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  transition: left 0.4s;
}

.menubar-icon {
  height: 32px;
  width: 32px;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  top: 76px !important;
}

.MuiMenuItem-root:hover .dropdown-icon svg path {
  fill: #000000;
}

@media (min-width: 991px) {
  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .none-sidebar {
    left: -335px;
  }

  .sidebar-overlay {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
  }
}

.img-white {
  filter: brightness(0) invert(1);
}
.top-28 {
  top: 28%;
}
.top-19 {
  top: 19%;
}
.color-8B8C8D {
  color: var(--color-8B8C8D);
}
.accordian_border {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
}
.footage-accordian .accordion-item {
  border: none !important;
}
.active-left {
  left: 0;
  z-index: 2;
  box-shadow: 4px 0px 15px transparent;
}

@media (max-width: 1599.98px) {
  .modal-video-height {
    height: 470px !important;
  }

  .creater-sidebar {
    width: 300px;
  }

  .dashboard-section {
    margin-left: 300px;
    width: calc(100vw - 285px);
  }

  #video-modal.modal-dialog {
    max-width: 1200px !important;
  }
}

@media (min-width: 1600px) {
  .modal-video-height {
    height: 705px !important;
  }

  .clear-notificatin-modal .modal-dialog {
    max-width: 600px;
    height: 500px;
  }

  .clear-notificatin-modal .modal-content {
    width: 600px;
  }

  .view-applicants-modal .modal-dialog {
    max-width: 949px;
    max-height: 799px;
  }

  .view-job-applicants-modal .modal-dialog {
    max-width: 949px;
  }

  .dashboard-section {
    margin-left: 300px;
    width: calc(100vw - 285px);
  }

  #video-modal.modal-dialog {
    max-width: 1320px !important;
  }

  #video-modal .modal-content {
    padding: 20px;
  }

  .h-lg-screen {
    height: 100vh;
    overflow: hidden;
  }
}

@media (max-width: 1199.98px) {
  .dashboard-section {
    width: 100%;
    padding-right: 15px;
    margin-left: 0px;
    padding-left: 15px;
  }
}

.change-color-btn {
  background-color: var(--color-white) !important;
  padding: 13px 0px !important;
  border-radius: 5px;
  margin: 0px 30px;
  cursor: pointer;
}

/* DASHBOARD */
.myjob .number-bg {
  background-color: var(--color-E7F2FE);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* PROFILE */
.profile-scroll {
  height: calc(100vh - 210px);
  overflow: auto;
}

.profile-scroll::-webkit-scrollbar {
  width: 0.6em;
}

.profile-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px white;
}

.profile-scroll::-webkit-scrollbar-thumb {
  background-color: white;
  outline: none;
}

.profile-upload {
  height: 282px;
  width: 282px;
  object-fit: contain;
}

/* job feed types */

.border-right {
  border-right: 1px solid var(--color-E8E8E8);
}

.search-field .form-control {
  border-radius: 51px;
  border: 1px solid #b9babb;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 26px;
  padding-right: 37px;
}

.search-field .form-control:focus {
  border: 1px solid #b9babb !important;
}

.search-icon {
  position: absolute;
  right: 18px;
  top: 16px;
}

.job-feed-card {
  border: 1px solid #b9babb;
  transition: 0.3s;
}

.job-feed-card:hover {
  background-color: white;
  border: 1px solid transparent;
  box-shadow: 0px 0px 10px #b9babb82;
}

@media (min-width: 1700px) {
  .job-feed-text {
    padding-right: 104px;
  }
}

@media (min-width: 1200px) {
  .job-feed-type-section {
    width: 400px;
  }

  .view-job-applicants-modal .modal-dialog {
    max-width: 900px;
  }
}

.card-scroll {
  height: calc(100vh - 250px);
  overflow: auto;
  /* cursor: pointer; */
  overflow-x: hidden;
}

.card-scroll::-webkit-scrollbar {
  width: 0.6em;
}

.card-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
}

.card-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: none;
}

/* MY JOB */
@media (min-width: 1700px) {
  .my-job-text {
    padding-right: 74px !important;
  }
}

.active-blue-dot {
  width: 13px;
  height: 13px;
  background-color: var(--color-127CF9);
  border-radius: 50%;
}

/* NOTIFICATION */

.border-bottom {
  border-bottom: 1px solid var(--color-E8E8E8);
}

.border-bottom-B9BABB {
  border-bottom: 1px solid #b9babb;
}

.clear-notificatin-modal .modal-header .modal-close-btn {
  background: url("../img/png/crossIcon.png") !important;
  background-repeat: no-repeat;
  background-position: center;
  width: 36px;
  height: 36px;
}

.view-applicants-modal .modal-header .btn-close-modal {
  background: url("../img/png/crossIcon.png") !important;
  background-repeat: no-repeat;
  background-position: center;
  width: 36px;
  height: 36px;
}

.job-applicants-successfully-modal .modal-header .btn-close {
  background: url("../img/png/crossIcon.png") !important;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
}

.view-job-applicants-modal .modal-header .btn-close {
  background: url("../img/png/back-icon-img.png") !important;
  background-repeat: no-repeat;
  background-position: center;
  width: 10px;
  height: 7px;
}

.notification-section:hover {
  box-shadow: 0px 10px 15px #eff3f7;
  border: 1px solid transparent;
}

.notification-section {
  border: 1px solid #e8e8e8;
}

.notification-scroll {
  height: calc(100vh - 280px);
  overflow: auto;
  cursor: pointer;
}
.view-job-scroll {
  height: calc(100vh - 130px);
  overflow: auto;
  cursor: pointer;
}

.notification-scroll::-webkit-scrollbar {
  width: 0.6em;
}

.notification-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px white;
}

.notification-scroll::-webkit-scrollbar-thumb {
  background-color: white;
  outline: none;
}
.view-job-scroll::-webkit-scrollbar {
  width: 0.6em;
}

.view-job-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px white;
}

.view-job-scroll::-webkit-scrollbar-thumb {
  background-color: white;
  outline: none;
}

.notification-user-img {
  height: 65px;
  width: 65px;
  border-radius: 50%;
}

@media (max-width: 475px) {
  .max-sm-w-228 {
    max-width: 228px;
  }
}

@media (max-width: 375px) {
  .notification-user-img {
    height: 60px;
    width: 60px;
  }
}

.text-wrap-css {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

.text-wrap-css-82 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 82px;
}

.text-wrap-css-350 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px;
}

.wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1200px) {
  .wrap {
    width: 200px;
  }
}

@media (max-width: 567.98px) {
  .wrap {
    width: 55px;
  }

  .menubar-icon {
    height: 27px;
    width: 27px;
  }
}

.h-100vh {
  height: 100vh;
}

@media (min-width: 1200px) {
  .calc-height-xxl-350 {
    height: calc(100vh - 350px);
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .calc-height-xl-550 {
    height: calc(100vh - 550px);
  }
}

.overflow-auto {
  overflow: auto;
}

.job-scrool::-webkit-scrollbar {
  width: 10px;
}

.job-scrool::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.job-scrool::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 10px;
}

.job-scrool::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary);
}

.fs-xs-14 {
  font-size: 14px !important;
}

.ff-Project {
  font-family: "Montserrat";
}

.dash_head {
  font-size: var(--font-xl);
  font-weight: 500;
  color: var(--color-17191C);
}

.rounded-50 {
  border-radius: 50%;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .head_earn {
    font-size: var(--font-22) !important;
  }
}

.head_earn {
  font-weight: 600;
  font-size: var(--font-md);
  color: var(--color-17191C);
}

.add-acc-btn {
  background-color: var(--color-127CF9);
  font-size: var(--font-smll);
  font-weight: 600;
  color: #ffffff;
  border: none;
  border-radius: 5px;
}

.add-acc-btn:hover {
  background-color: #4694ee;
}

.payment-cancel-btn {
  background-color: var(--color-F8F8F8);
  border: none;
  font-size: var(--font-smll);
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid var(--color-E8E8E8);
}

.payment-cancel-btn:hover {
  background-color: white;
}

.w-49 {
  width: 49%;
}

.jobs-head {
  font-size: var(--font-smml);
  color: var(--color-17191C);
  font-weight: 600;
}

.jobs-client {
  font-size: var(--font-xxs);
  font-weight: 500;
  color: var(--color-5D5E60);
}

.job-price {
  color: var(--color-primary);
  font-weight: 600;
  font-size: var(--font-sm);
}

.rounded-10 {
  border-radius: 10px;
}

.object-contain {
  object-fit: contain;
}

.border-1-E8E8E8 {
  border: 1px solid #e8e8e8;
}

.box-shadow-hover:hover {
  box-shadow: 0px 10px 15px #eff3f7 !important;
  border: unset;
}

.message-indicatior {
  border: 1px solid #e8e8e8;
  border-radius: 50%;
}

.indicator_para {
  font-weight: 500;
  font-size: var(--font-smml);
  color: var(--color-59A3FB);
}

.h-30 {
  height: 30px;
}

.w-30 {
  width: 30px;
}

.message-para {
  font-weight: 500;
  font-size: var(--font-smml);
  color: var(--color-17191C);
}

.nojob-text {
  font-size: var(--font-lg);
  color: var(--color-17191C);
  font-weight: 600;
}

.find-btn {
  background-color: var(--color-primary) !important;
  box-shadow: 0px 11px 25px -9px #96bfef;
  padding: 13px 13px !important;
  border-radius: 3px;
  border: 1px solid var(--color-primary);
  cursor: pointer;
  margin: 0px 30px;
  font-size: var(--font-smll);
  font-weight: 600;
}

@media (min-width: 768px) {
  .min-height-md-400 {
    min-height: 400px;
  }
}

.applications-job {
  background: #ffffff;
  border: 1px solid var(--color-E8E8E8);
  border-radius: 10px;
}

.applications-job:hover {
  box-shadow: 0px 10px 15px #eff3f7;
  border-color: var(--color-white);
  cursor: pointer;
}

.myjob .job-list {
  background: #ffffff;
  border: 1px solid var(--color-E8E8E8);
  border-radius: 10px;
}

.myjob .job-list:hover {
  box-shadow: 0px 10px 15px #eff3f7;
  border-color: var(--color-white);
  cursor: pointer;
}

.chatactive {
  border-color: var(--color-white) !important;
  cursor: pointer;
  background-color: #e7f2fe !important;
}

.myjob .job-list:hover .number-bg {
  box-shadow: 0px 10px 15px #eff3f7;
  border-color: var(--color-white);
  background-color: var(--color-127CF9);
}

.myjob .job-list:hover .no-list {
  color: var(--color-white);
}
.max_height_745 {
  max-height: 745px;
}
.myjob {
  background: #ffffff;
  /* box-shadow: 0px 3px 15px #eff3f7; */
  border-radius: 10px;
}

.myjob .h-calc-100vh-350 {
  height: calc(100vh - 350px);
}

.h-calc-100vh-170 {
  height: calc(100vh - 170px);
}

.myjob .scroll-bar-none::-webkit-scrollbar {
  display: none;
}

.myjob .no-list {
  font-size: var(--font-smll);
  font-weight: 500;
  color: var(--color-59A3FB);
}

.myjob .view-all:hover {
  color: var(--color-primary);
  cursor: pointer;
}

.border-top-1-E8E8E8 {
  border-top: 1px solid var(--color-E8E8E8);
}

.myjob .massage-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.dashborad-profile {
  background: #ffffff;
  box-shadow: 0px 3px 15px #eff3f7;
  border-radius: 25px;
  height: 50px;
  width: 220px;
}

.pr-20 {
  padding-right: 20px;
}

.dropdown .profile-dropdown {
  font-size: var(--font-smll);
  font-weight: 500;
  color: var(--color-17191C) !important;
  background-color: var(--color-white) !important;
}

.dashborad-profile .dropdown-toggle::after {
  background-image: url("../img/png/array-icon-profile.png") !important;
  height: 15px;
  width: 15px;
  background-position: center;
  border-top: unset !important;
  background-repeat: no-repeat;
  margin-left: 20px;
}

.dropdown .btn-primary:hover {
  background-color: var(--color-white) !important;
  color: var(--color-17191C) !important;
}

.dropdown .dropdown-item.active,
.dropdown-item:active {
  background-color: var(--color-white) !important;
  color: var(--color-17191C) !important;
}

.pr-10 {
  padding-right: 10px;
}

.dashboard-icon {
  width: 60px;
  height: 60px;
}

.dropdown .dropdown-menu.show {
  transform: translate3d(9px, 39px, 0px) !important;
}

.dropdown .btn-primary:focus {
  background-color: var(--color-white) !important;
  color: var(--color-17191C) !important;
}
@media (max-width: 1200px) {
  .view-job-scroll {
    height: calc(100vh - 120px);
  }
}
@media (min-width: 1199.98px) and (max-width: 1279.98px) {
  .dashboard-icon {
    width: 50px;
    height: 50px;
  }

  .smile-icon img {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 767.98px) {
  .dashborad-profile {
    width: 180px;
  }

  .dashborad-profile .dropdown-toggle::after {
    margin-left: 40px;
  }
}

@media (max-width: 400.98px) {
  .dashboard-section {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 374.98px) {
  .fs-xs-25 {
    font-size: var(--font-md);
  }

  .dashboard-profile-img {
    width: 35px;
    height: 35px;
  }

  .myjob .massage-img {
    width: 40px;
    height: 40px;
  }
}

/* CREATE JOB FORM CSS */

/* ====== our new commen class ==== */
.border-bottom-e8e8e8 {
  border-bottom: 1px solid var(--color-E8E8E8);
}

.f-w-500 {
  font-weight: 500;
}

.br-3 {
  border-radius: 3px;
}

.border-b9babb {
  border: 1px solid var(--color-B9BABB);
}

.form-control:focus {
  box-shadow: none !important;
  border: 1px solid var(--color-B9BABB);
  outline: none !important;
}

.main-box-shadow {
  box-shadow: 0px 3px 15px #eff3f7;
}

textarea {
  resize: none;
}

.form-next-btn {
  padding: 16px 24px;
  border-radius: 3px;
  background-color: var(--color-127CF9);
  color: var(--color-white);
  font-size: var(--font-smll);
  font-weight: 600;
}

.form-next-btn:hover {
  transition: all 0.5s ease-in-out;
  background-color: #327ed6;
}

.form-Previous-btn {
  padding: 16px 24px;
  border-radius: 3px;
  background-color: var(--color-F8F8F8);
  color: var(--color-5D5E60);
  font-size: var(--font-smll);
  font-weight: 500;
  border: 1px solid var(--color-E8E8E8);
}

.form-Previous-btn:hover {
  transition: all 0.5s ease-in-out;
  background-color: var(--color-white);
}

.form-select {
  background-color: #fff;
  background-image: url("../img//svg/dropdownIcon.svg") !important;
  background-position-x: 92.2%;
  cursor: pointer;
}

.cursor-poniter {
  cursor: pointer;
}

/* .overflow-x-hidden {
  overflow-x: hidden;
} */
/* video modal css start here */

.video-section-modal .modal-content {
  width: 1320px;
}

/* end here modal css */
/* VIEW JOB CSS  */
:root {
  --font-24: 24px;
  --color-DCDDDD: #dcdddd;
  --color-000000: #000000;
}

.text-0000 {
  color: var(--color-000000) !important;
}

.text-dcdddd {
  color: var(--color-DCDDDD) !important;
}

.bg-f8f8f8 {
  background-color: var(--color-F8F8F8) !important;
}

.font-24 {
  font-size: var(--font-24) !important;
}

.font-md {
  font-size: var(--font-md) !important;
}

.border-e8e8e8 {
  border: 1px solid var(--color-E8E8E8);
}

.hr {
  border-bottom: 1px solid var(--color-E8E8E8);
}

.m-0-20 {
  margin: 0 20px;
}

.Start-Dispute-hover {
  background-color: var(--color-F8F8F8);
}

.Start-Dispute-hover:hover {
  transition: all 0.5s ease-in-out;
  background-color: var(--color-white);
}

.delivery-btn {
  font-weight: 600;
  background-color: var(--color-127CF9);
  border-radius: 3px;
  font-size: var(--font-smll);
  color: var(--color-white) !important;
}

.w-100-video video {
  width: unset;
}

.delivery-btn:hover {
  transition: all 0.5s ease-in-out;
  background-color: #4694ee;
  box-shadow: 0px 7px 20px -9px #96bfef;
}

button:focus-visible {
  box-shadow: none !important;
}

.form-control:focus {
  border: 1px solid var(--color-B9BABB) !important;
}

.view-job-btn {
  font-size: var(--font-smll);
  font-weight: 600;
  color: var(--color-white) !important;
  padding: 8px 16px;
  background-color: var(--color-127CF9);
  border-radius: 3px;
}

.view-job-btn:hover {
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 4px 10px -9px #96bfef;
  background-color: #327ed6;
}

/* model start */
/* ===========  modals  =================  */

.accordion-shadow:hover {
  background-color: var(--color-E7F2FE);
  box-shadow: 0px 3px 15px #eff3f7;
}

.accordion-shadow:hover .accordion-button {
  background-color: var(--color-E7F2FE);
  box-shadow: 0px 3px 15px #eff3f7;
}

.accordion-button {
  padding: 16px 16px 6px 24px !important;
}

.accordion-button::after {
  background-image: url("../img/png/up.png");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../img/png/down.png");
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: white !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.modal-header {
  padding-bottom: 8px !important;
  border-bottom: none !important;
}

.modal-footer {
  padding-top: 0px !important;
  border-top: none !important;
}

.pb-6 {
  padding-bottom: 6px !important;
}

.mx-523 {
  max-width: 523px !important;
}

.focus:focus {
  outline: none;
  box-shadow: none;
}

.cursor-pointer {
  cursor: pointer;
}

/* chat css start here   */

.br-10 {
  border-radius: 10px;
}

.br-50 {
  border-radius: 50px;
}

.float-end.dropdown-toggle::after {
  content: unset;
}

.dropdown:focus-visible {
  outline: unset;
}

.float-end:focus-visible {
  outline: unset;
}

.float-end.btn-primary:active:focus {
  box-shadow: unset;
}

.float-end.btn-check:hover + .btn-primary,
.btn-primary:hover {
  border-color: unset;
}

.three-dots .dropdown-menu.show {
  width: 319px;
  border-radius: 10px;
}

.three-dots .dropdown-item {
  padding: 10px 20px;
}

.border-end-e8e8e8 {
  border-right: 1px solid var(--color-E8E8E8);
}

.border-bottom-e8e8e8 {
  border-bottom: 1px solid var(--color-E8E8E8);
}

.bg-e5e5e5 {
  background-color: var(--color-E5E5E5);
}

.msg-numbers {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--color-E7F2FE);
}

.fs-16-500 {
  font-size: var(--font-smll);
  font-weight: 500;
}

.fs-16-600 {
  font-size: var(--font-smll);
  font-weight: 600;
}

.fs-39-500 {
  font-size: var(--font-xl);
  font-weight: 500;
}

.fs-25-600 {
  font-size: var(--font-md);
  font-weight: 600;
}

/* COLOR VARIAVLES  */
.color-17191c {
  color: var(--color-17191C);
}

.color-88befc {
  color: var(--color-88BEFC);
}

.color-5d5e60 {
  color: var(--color-5D5E60);
}

.color-8B8C8D {
  color: var(--color-8B8C8D);
}

.color-fff {
  color: var(--color-white);
}

/* chat css */
.people {
  margin: 10px auto;
  padding: 8px 12px;
  border-radius: 10px;
  border: 1px solid var(--color-E8E8E8);
  cursor: pointer;
}

.people:hover {
  box-shadow: 0px 10px 15px #eff3f7;
}

.people:hover .msg-numbers {
  background-color: var(--color-127CF9);
}

.people-active {
  background-color: var(--color-E7F2FE);
}

.people-active .msg-numbers {
  display: none;
}

.massage-list-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.people-name {
  display: -webkit-box;

  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.bg-number {
  background-color: var(--color-E7F2FE);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-number-dipsute {
  background-color: var(--color-FFF6E7);
  width: 30px;
  height: 30px;
  color: var(--color-FDD288);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myjob .job-list:hover .no-list-dipsute {
  color: var(--color-white);
}

.myjob .job-list:hover .bg-number-dipsute {
  box-shadow: 0px 10px 15px #eff3f7;
  border-color: var(--color-white);
  background-color: var(--color-FDD288);
}

.myjob .job-list:hover .bg-number {
  box-shadow: 0px 10px 15px #eff3f7;
  border-color: var(--color-white);
  background-color: var(--color-127CF9);
}

.open-chat-person {
  cursor: pointer;
}

.h-calc-100vh-190 {
  height: calc(100vh - 190px);
}

.h-calc-100vh-350 {
  height: calc(100vh - 350px);
}

.ml-_12 {
  margin-left: -12px;
}

.h-35 {
  height: 35px;
}

.w-35 {
  width: 35px;
}

.h-calc-100vh-365 {
  height: calc(100vh - 365px);
}

.h-calc-100vh-727 {
  height: calc(100vh - 727px);
}

.h-calc-100vh-769 {
  height: calc(100vh - 769px);
}

.you-message {
  float: left;
  border-radius: 3px 23px 23px 23px;
  padding: 10px 10px 10px 23px;
  background-color: var(--color-F8F8F8);
  margin-right: 20px;
}

.disptue-message {
  float: left;
  border-radius: 3px 23px 23px 23px;
  padding: 11px 23px;
  background-color: var(--color-FFF6E7);
  margin-right: 20px;
}

.you-message-img {
  float: left;
  border-radius: 3px 23px 23px 23px;

  background-color: var(--color-F8F8F8);
  margin-right: 20px;
}

.me-message-img {
  border-radius: 3px;
  background-color: var(--color-127CF9);
  margin-left: 20px;
}

.me-message {
  border-radius: 23px 3px 23px 23px;
  padding: 8px 14px;
  background-color: var(--color-127CF9);
  /* margin-left: 20px; */
}

.chat-height {
  height: calc(100vh - 360px);
}

.msg-send-input {
  border-radius: 29.5px;
  padding: 7px;
  border: 1px solid var(--color-E8E8E8);
}

.chat-icon-section {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.msg-send-input input {
  outline: none;
}

.pl-10 {
  padding-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.send-message {
  border: 1px solid var(--color-127CF9);
  border-radius: 50%;
  background-color: var(--color-127CF9);
  height: 48px;
  width: 48px;
}

.h-calc-100vh-440 {
  height: calc(100vh - 440px);
}

.h-calc-100vh-270 {
  height: calc(100vh - 270px);
}

.chat-users {
  max-height: 880px;
  overflow-y: auto;
}

.chat-box::-webkit-scrollbar {
  width: 11px;
}

.chat-box::-webkit-scrollbar-thumb {
  background-color: var(--color-127CF9);
  border-radius: 5px;
}

.chat-users::-webkit-scrollbar {
  width: 0px;
}

.view-applicants-modal-scroll-bar {
  height: calc(80vh - 180px);
}

.view-applicants-modal-scroll-bar::-webkit-scrollbar {
  width: 7px;
}

.view-applicants-modal {
  padding-left: 0px !important;
}

.view-applicants-modal-scroll-bar::-webkit-scrollbar-thumb {
  background-color: var(--color-127CF9);
  border-radius: 5px;
}

/* MEDIA QUERY RESPONSIVENESS ABOVE 1620 */
@media (min-width: 1620px) {
  .max-w-xxxl-1485 {
    max-width: 1485px !important;
  }

  .pt-30 {
    padding-top: 30px !important;
  }

  .mt-16 {
    margin-top: 16px;
  }

  .navigation {
    max-width: 255px;
    position: relative !important;
  }
}

@media (max-width: 991.98px) {
  .profile-scroll {
    height: calc(100vh - 140px);
  }
}

.chat-block {
  display: block !important;
}

/* MEDIA QUERY RESPONSIVENESS ABOVE 1600 */

@media (max-width: 1599.98px) {
  .massage-list-img {
    width: 45px;
    height: 45px;
  }

  .bg-number {
    width: 25px;
    height: 25px;
  }
}

/* MEDIA QUERY RESPONSIVENESS BETWEEN 1200 AND 1620  */

@media (min-width: 1200px) and (max-width: 1619.98px) {
  :root {
    --font-xl: 30px;
    --font-md: 22px;
    --font-smll: 13px;
  }

  .chat-users {
    /* min-height: 590px; */
    max-height: 565px;
    overflow-y: auto;
  }

  .navigation {
    max-width: 255px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  :root {
    --font-xl: 28px;
    --font-md: 20px;
    --font-smll: 14px;
  }

  .chat-users {
    min-height: 620px;
    max-height: 630px;

    overflow-y: auto;
  }

  .navigation {
    max-width: 255px;
    display: none !important;
  }

  .navigation a {
    padding: 11px 80px 10px 20px;
    margin: 18px 30px;
  }

  .sidebar-open {
    z-index: 1;
    position: absolute;
    display: flex !important;
    transition: all 1s ease-in-out;
  }
}

@media (max-width: 991.98px) {
  .chat-none {
    display: none;
  }

  .chat-height {
    height: calc(100vh - 265px);
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  :root {
    --font-xl: 26px;
    --font-md: 20px;
    --font-smll: 14px;
  }

  .chat-users {
    max-height: 630px;
    overflow-y: auto;
  }

  .navigation {
    max-width: 225px;
    display: none !important;
  }

  .navigation a {
    padding: 8px 34px 8px 17px;
    margin: 10px 17px;
  }

  .hamburger-2 {
    left: 15px;
  }

  .hamburger-2 .line {
    width: 20px;
    height: 2px;
  }

  .people {
    padding: 4px 5px;
  }

  .sidebar-open {
    z-index: 1;
    position: absolute;
    display: flex !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  :root {
    --font-xl: 26px;
    --font-md: 18px;
    --font-smll: 14px;
  }

  .chat-users {
    max-height: 590px;
    overflow-y: auto;
  }

  .msg-numbers {
    padding-top: 0 !important;
    height: 20px;
    width: 20px;
  }

  .navigation {
    display: none !important;
  }

  .navigation a {
    padding: 8px 34px 8px 17px;
    margin: 10px 30px;
  }

  .hamburger-2 {
    left: 15px;
  }

  .hamburger-2 .line {
    width: 20px;
    height: 2px;
  }

  .sidebar-open {
    z-index: 1;
    position: absolute;
    display: flex !important;
  }

  .people {
    border: 0px;
    padding: 0;
  }
}

.mr-20 {
  margin-right: 20px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-10 {
  margin-right: 10px;
}

/* chat css end here  */
/* VIEW JOB */
@media (min-width: 991px) {
  .veiw-job-heading {
    padding-right: 144px;
  }
}

.job-scroll {
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 285px);
}

.job-scroll::-webkit-scrollbar {
  width: 0.6em;
}

.job-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px white;
}

.siderbar-scrollbar::-webkit-scrollbar-thumb {
  background-color: white;
  outline: none;
}
.siderbar-scrollbar::-webkit-scrollbar {
  width: 0.6em;
}

.siderbar-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px white;
}

.siderbar-scrollbar::-webkit-scrollbar-thumb {
  background-color: white;
  outline: none;
}

/* Add Actor Sidebar css start here  */
.Sidebar-width {
  width: 791px;
}

#add-actor.offcanvas-start {
  right: 0 !important;
  left: unset;
  float: right !important;
}

.offcanvas {
  right: 0 !important;
  left: unset !important;
}

.Add-actor-input {
  height: 56px;
  width: 205px;
  border: 1px solid var(--color-B9BABB);
  border-radius: 3px;
  display: flex;
  align-items: center;
}

/* .Add-actor-input input {
  margin-left: 15px;
} */

.Add-actor-input input::placeholder {
  font-size: var(--font-smll);
  font-weight: 500;
  color: var(--color-8B8C8D);
}

input:focus {
  border: unset !important;
}

.Add-actor-input select {
  width: 160px;
  border: 0;
  background-color: var(--color-white);
  height: 24px;
  margin-left: 15px;
  font-size: var(--font-smll);
}

select:focus-visible {
  outline: unset !important;
}

.add-actor-card {
  width: 635px;
}

.Add-actor-input-description {
  height: 128px;
  border: 1px solid var(--color-B9BABB);
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.Add-actor-input-duration {
  border: 1px solid var(--color-B9BABB);
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.Add-actor-input-description textarea {
  font-size: var(--font-smll);
  font-weight: 500;
  color: var(--color-8B8C8D);
}

.form-control:focus {
  box-shadow: unset !important;
}

textarea:focus-visible {
  outline: unset !important;
}

/* .btn-check:hover + .btn-primary,
.btn-primary:hover {
  border-color: var(--color-127CF9) !important;
} */
.Sidebar-width.offcanvas-end {
  top: 0;
  right: 0;
  width: 791px !important;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  /* transform: translateX(100%); */
}

.remove-edit .atech-primary-button .edit-pencil svg path:hover {
  fill: var(--color-127CF9);
}

.remove-edit .atech-primary-button:hover svg path {
  fill: var(--color-127CF9);
}

.remove-edit {
  width: 280px;
}

.create-job-btn {
  padding-right: 24px;
  padding-left: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
}

/* Add Actor Sidebar css end here  */

/* Step - 4 css Start here  */
.drop-none {
  display: flex;
  max-height: 0;
  overflow: hidden;
  transition: 0.8s;
}

.sidebardropdown {
  max-height: 400px;
  transition: 0.8s;
}

/* =============================================== DISPUTES */
.dispute-height {
  height: calc(100vh - 580px);
}

/* end here step-4 */

/* Job Application View Cs Start here  */
.job-application-img-width {
  width: 196px;
  height: 196px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.job-application-img-width img {
  border-radius: 50%;
}

/* Job Application View Cs End here  */

/* media Query  start Here */
@media (min-width: 1400px) and (max-width: 1599.98px) {
  .common-heading-Medium {
    font-size: var(--font-md);
  }
}

.myjob .h-lg-calc-100vh-440 {
  max-height: calc(100vh - 440px);
}

.myjob .h-lg-calc-100vh-440 {
  max-height: calc(100vh - 440px);
}

@media (max-width: 1199.98px) {
  .scroll-bar-add {
    height: calc(100vh - 350px);
    overflow: auto;
  }

  .three-dots .dropdown-menu.show {
    width: 210px;
    inset: 0px auto auto -180px !important;
  }
}

@media (min-width: 992px) {
  .pr-lg-20 {
    padding-right: 20px;
  }
}

@media (max-width: 991.98px) {
  .friend-list-none {
    display: none;
  }

  .dispute-height {
    height: calc(100vh - 490px) !important;
  }

  .border-end-e8e8e8 {
    border: 0px;
  }

  .show-chat.hide-chat {
    display: flex;
  }

  .hide-chat {
    display: none;
  }
}

.sender-message .my-message-video .react-player__preview .react-player__shadow {
  width: 283px !important;
  height: 159px !important;
}

@media (max-width: 575.98px) {
  :root {
    --font-xl: 17px;
    --font-md: 13px;
    --font-smll: 12px;
  }

  .job-application-img-width {
    width: 120px;
    height: 120px;
  }

  .size-video-icon {
    width: 40px;
    height: 40px;
  }

  .modal-video-height {
    height: 130px !important;
  }

  .w-sm-45 {
    width: 45px;
  }

  .h-sm-45 {
    height: 45px;
  }

  .navigation {
    display: none !important;
  }

  .navigation a {
    padding: 8px 34px 8px 17px;
    margin: 10px 30px;
  }

  .hamburger-2 {
    left: 10px;
  }

  .hamburger-2 .line {
    width: 20px;
    height: 2px;
  }

  .vh-60 {
    height: 77vh;
    overflow: scroll;
  }

  .vh-60::-webkit-scrollbar {
    width: 0px;
  }

  .creator {
    padding: 1px 31px 1px 1px !important;
  }

  .border-end-e8e8e8 {
    border: 0px;
  }

  .messages-image {
    width: 45px !important;
    height: 45px !important;
  }

  .sidebar-open {
    z-index: 1;
    position: absolute;
    display: flex !important;
  }

  .sender-message img {
    width: 200px !important;
    height: 100px !important;
  }

  .your-message img {
    width: 200px !important;
    height: 100px !important;
  }

  .sender-message
    .my-message-video
    .react-player__preview
    .react-player__shadow {
    width: 200px !important;
    height: 100px !important;
  }

  .your-message
    .your-message-video
    .react-player__preview
    .react-player__shadow {
    width: 200px !important;
    height: 100px !important;
  }

  .open-chat-person {
    display: none;
  }
}

.border-bottom-e8e8e8 {
  border-bottom: 1px solid var(--color-E8E8E8);
}

/* ================================================================ CREATE JOB SECTION  */

.create-job-scroll {
  height: calc(100vh - 82px);
  overflow: auto;
}

.create-job-scroll::-webkit-scrollbar {
  width: 0.6em;
}

.create-job-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px white;
}

.create-job-scroll::-webkit-scrollbar-thumb {
  background-color: white;
  outline: none;
}

.css-yk16xz-control {
  height: 100%;
}

/* .select-field {
  height: 100%;
} */
.css-yk16xz-control {
  padding: 9px;
}

.css-1pahdxg-control {
  outline: none;
  padding: 10px;
  box-shadow: 0 0 0 1px transparent !important;
  border-color: hsl(0, 0%, 70%) !important;
}

.css-yk16xz-control:hover {
  border-color: hsl(0, 0%, 70%) !important;
}

.css-1pahdxg-control:hover {
  height: 100%;
  border-color: hsl(0, 0%, 70%) !important;
  box-shadow: 0 0 0 1px transparent !important;
}

.profile-img {
  height: 40px;
  object-fit: cover;
  width: 40px;
  border-radius: 50%;
}

/* =============================================================================== PROFILE MODAL ========================================================================== */
.profile-modal .react-player__preview {
  border-radius: 10px;
}

.model-border {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

.border-bottom-grey {
  background-color: #b9babb;
  height: 2px;
  width: 100%;
}

.white-space-nowrap {
  white-space: nowrap;
}

.overflow-text-scrollbar {
  overflow-x: auto;
  flex-wrap: nowrap;
  width: 100%;
}

.overflow-text-scrollbar::-webkit-scrollbar {
  width: 1em;
  height: 0.2em;
}

.overflow-text-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
}

.overflow-text-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 1px solid transparent;
}

/* ================================================================================ CHAT MESSGAE ATTACHMENT ====================================================================== */
.Attachment {
  border: 1px solid #e8e8e8;
  height: 48px;
  width: 48px;
  border-radius: 50%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* ============================================================================== ADMIN DASHBOARD ========================================================================= */
.counting-number {
  background-color: var(--color-E7F2FE);
  height: 35px;
  width: 35px;
  border-radius: 50%;
  font-size: var(--font-smll);
}

.admin-user-image {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.counting-number p {
  font-size: var(--font-smll);
  color: var(--color-127CF9);
  font-weight: 500;
}

.footage-accordian .accordion-button {
  padding: 16px 16px 14px 24px !important;
}

.footage-accordian .accordion-button::after {
  background-image: url("../img/svg/plus.svg");
}

.footage-accordian .accordion-button:not(.collapsed)::after {
  background-image: url("../img/svg/line.svg");
}

.footage-accordian .accordion-button:not(.collapsed) {
  color: #000000 !important;
}

.messages-height {
  height: calc(100vh - 365px);
}

.messages-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

/* ========================================================================== JOB APPLICATION SECTION MODAL =========================================================== */
.applicant-user-image {
  width: 196px;
  height: 196px;
  border-radius: 50%;
  object-fit: cover;
}
.margin-left-10 {
  margin-left: 10px !important;
}
/* .infinite_scroll .infinite-scroll-component__outerdiv {
  height: 100%;
} */

.creator-vid-modal .modal-video-height video {
  object-fit: cover;
}

.object-fit-cover {
  object-fit: cover;
}

/* .infinite_scroll .infinite-scroll-component {
  height: 100% !important;
} */

.image-height .infinite-scroll-component__outerdiv {
  height: 100%;
}

/* .image-height .infinite-scroll-component__outerdiv .infinite-scroll-component {
  height: 100% !important;
} */
.deliver-img {
  height: 193px;
  object-fit: cover;
}

@media (max-width: 767.98px) {
  .applicant-user-image {
    width: 139px;
    height: 139px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.giveMeEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* number of lines to show */
}

.star-img g path {
  fill: #faa611;
}

.star-img-stroke g path {
  stroke: #b9babb;
}

/* .creator-panel-height {
  height: calc(100vh - 370px);
} */

@media (min-width: 1400px) {
  .image-height
    .infinite-scroll-component__outerdiv
    .infinite-scroll-component {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.view-job-height {
  height: calc(100vh - 215px);
}

.sender-message {
  background-color: var(--color-127CF9);
  border-radius: 10px;
}

.your-message {
  border-radius: 10px;
  background-color: var(--color-F8F8F8);
}

.your-message img {
  padding: 4px 4px 4px 4px;
  border-radius: 10px;
  width: 293px;
  height: 159px;
  object-fit: contain;
}

.your-message img {
  padding: 4px 4px 4px 4px;
  border-radius: 10px;
  width: 293px;
  height: 159px;
  object-fit: contain;
}

.your-message .your-message-video .react-player__preview {
  border-radius: 10px !important;
}

.your-message .your-message-video .react-player__preview {
  width: 293px !important;
  height: 159px !important;
}

.your-message .your-message-video video {
  width: 293px !important;
  height: 159px !important;
}

.your-message .your-message-video {
  padding: 4px 4px 4px 4px;
}

.sender-message img {
  padding: 4px 4px 4px 4px;
  border-radius: 10px;
  width: 293px;
  height: 159px;
  object-fit: contain;
}

.sender-message .my-message-video .react-player__preview {
  border-radius: 10px !important;
}

.sender-message .my-message-video video {
  width: 293px !important;
  height: 159px !important;
}

.sender-message .my-message-video {
  padding: 4px 4px 4px 4px;
}

/* ========================================== ADMIN DASHBOARD SECTION HERE================ */
.dahboard-color {
  box-shadow: 0px 10px 15px #eff3f7;
  border-radius: 10px;
  background-color: var(--color-white);
}

.admin-dahboard-height {
  overflow: auto;
}

.admin-dahboard-height::-webkit-scrollbar {
  width: 0.3em;
}

.admin-dahboard-height::-webkit-scrollbar-thumb {
  background-color: var(--color-127CF9);
  border-radius: 10px;
}

.admin-message-height {
  overflow: auto;
}

.admin-message-height::-webkit-scrollbar {
  width: 0.3em;
}

.admin-message-height::-webkit-scrollbar-thumb {
  background-color: var(--color-127CF9);
  border-radius: 10px;
}

.user_details {
  background-color: var(--color-E8E8E8);
  border-radius: 10px;
}

.user_image {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.main_height_adminDashboard {
  height: calc(100vh - 200px);
}

.overflow-auto {
  overflow: auto;
}

/* ========================================== CREATOR SECTION =========================== */
.creator_height {
  height: calc(100vh - 250px);
}

.creator_height::-webkit-scrollbar {
  width: 0.3em;
}

.creator_height::-webkit-scrollbar-thumb {
  background-color: var(--color-127CF9);
  border-radius: 10px;
}

/* ==========================================PAYMENT  */
.stripe-button {
  background-color: var(--color-127CF9);
  border-radius: 8px;
}

.payment-height {
  height: calc(100vh - 120px);
}

.payment-height::-webkit-scrollbar {
  width: 0.3em;
}

.payment-height::-webkit-scrollbar-thumb {
  background-color: var(--color-127CF9);
  border-radius: 10px;
}

/* ========================================= DISPUTES SECTION  */
.dispute_height {
  height: calc(100vh - 480px);
}

.dispute_height_420 {
  height: calc(100vh - 420px);
}

.notification-scroll .infinite-scroll-component__outerdiv {
  height: 100%;
}
.application-wrap {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* =========================================== JOB SECTION HERE  */

@media (min-width: 991.98px) {
  .overflow_hidden {
    overflow: hidden;
  }
}

@media (max-width: 991.98px) {
  .chat-box-height {
    height: calc(100vh - 130px);
  }

  .me-message {
    max-width: 350px !important;
  }

  .you-message {
    max-width: 350px !important;
  }
}

@media (max-width: 767.98px) {
  .text-wrap-css-md-194 {
    width: 304px !important;
  }

  .message_title_text {
    max-width: 160px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 574.98px) {
  .text-wrap-css-md-194 {
    width: 194px !important;
  }

  .send-message {
    padding: 4px 8px 4px 8px;
  }

  .send-message svg {
    width: 15px;
  }

  .notification_circle {
    position: absolute;
    top: -2px !important;
    right: -4px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    font-size: 10px !important;
    line-height: 8px !important;
    height: 16px !important;
    width: 16px !important;
    background-color: #127cf9;
  }
}

@media (max-width: 1400px) {
  .main_height_adminDashboard {
    height: calc(100vh - 295px);
  }
}

@media (min-width: 1400px) {
  .admin_job
    .infinite_scroll
    .infinite-scroll-component__outerdiv
    .infinite-scroll-component {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 575.98px) {
  .application-wrap {
    white-space: nowrap;
    width: 50px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.scrollbar_desgin::-webkit-scrollbar {
  width: 0px !important;
}

/* .chat-message-scroll-div::-webkit-scrollbar {
  width: 0px !important;
} */

.scroll-bar-add::-webkit-scrollbar {
  width: 0px !important;
}

.sign_up::-webkit-scrollbar {
  width: 0px !important;
}

.h-calc-100vh-250 {
  height: calc(100vh - 280px);
}

.h-calc-100vh-160 {
  height: calc(100vh - 160px);
}

@media (min-width: 1600px) {
  .place_event_section {
    overflow-y: auto !important;
    height: calc(100vh - 90px);
  }
}

.css-1uccc91-singleValue {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: var(--color-8B8C8D) !important;
  font-size: var(--font-smll);
}

.css-1wa3eu0-placeholder {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: var(--color-8B8C8D) !important;
  font-size: var(--font-smll);
}

.inputs_font_size {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: var(--color-8B8C8D) !important;
  font-size: var(--font-smll);
}

.textarea_focus_border_0:focus {
  border: none !important;
}

.cursor-poniter {
  cursor: pointer;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.notification_circle {
  position: absolute;
  /* z-index: -1; */
  top: -4px;
  right: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  line-height: 8px;
  height: 20px;
  width: 20px;
  background-color: #127cf9;
}

.h-calc-100vh-156 {
  height: calc(100vh - 156px);
}

.delivery-scroll {
  height: calc(100vh - 150px);
  overflow: auto;
  cursor: pointer;
}

.message_modal_job {
  background: #ffffff;
  border: 1px solid var(--color-E8E8E8);
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
}

.message_modal_job:hover {
  box-shadow: 0px 10px 15px #eff3f7;
  border-color: var(--color-white);
  cursor: pointer;
}

.message_modal .modal-body::-webkit-scrollbar {
  width: 0px !important;
}

.message_title_text {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.me-message {
  word-break: break-all;
  max-width: 283px;
}

.you-message {
  word-break: break-all;
  max-width: 283px;
}

.reject-btn {
  background-color: red;
  border: 1px solid red;
  color: white;
  transition: 0.3s;
  cursor: context-menu;
}

.accept-btn {
  background-color: green;
  border: 1px solid green;
  color: white;
  transition: 0.3s;
  cursor: context-menu;
}
.blue-btn {
  background-color: var(--color-127CF9);
  border: 1px solid var(--color-127CF9);
  color: white;
  transition: 0.3s;
  cursor: context-menu;
}
.word-break-text {
  word-break: break-all;
}
.vertical-align {
  vertical-align: middle;
}
.max-width-30 {
  max-width: 30px;
}
.earning_height {
  height: calc(100vh - 390px);
  overflow: auto;
}
.earning_height::-webkit-scrollbar {
  width: 0.6em;
}

.earning_height::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px white;
}

.earning_height::-webkit-scrollbar-thumb {
  background-color: white;
  outline: none;
}
.earning-text {
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn_shadow {
  box-shadow: 0px 11px 25px -9px #96bfef;
}
.earning_bg {
  background: #f8f8f8;
  /* Black-100 */

  border: 1px solid #e8e8e8;
  border-radius: 10px;
}
.text_hover {
  transition: 0.3s;
}
.text_hover:hover {
  color: var(--color-127CF9);
}

@media (max-width: 1200px) {
  .max-width-30 {
    max-width: 300px;
  }
}
@media (max-width: 767.98px) {
  .earning-text {
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
